import React from 'react'
import "./style.css"

export const MainContent = () => {
    return (
        <div>
            <div className="content__wrapper">
                <div className="content__title-wrapper">
                    <div className="main-content__title-top top">
                        <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Goldsworth
                                </h2>

                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Goldsworth
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Goldsworth
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Goldsworth
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Goldsworth
                                    </h2>
                                </div>
                            </div>
                            <br />
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Capital
                                </h2>

                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Capital
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Capital
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Capital
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Capital
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content">

                        {/* SYS */}
                        <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Syscoin
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Syscoin
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Syscoin
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Syscoin
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Syscoin
                                    </h2>
                                </div>
                            </div>

                            <div className="box__content">
                                <p>
                                    $SYS
                                </p>
                            </div>

                        </div>
                        {/* AAA */}
                        {/* <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Moon
                                </h2>

                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Moon
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Moon
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Moon
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Moon
                                    </h2>
                                </div>
                            </div>
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Rabbit
                                </h2>

                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Rabbit
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Rabbit
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Rabbit
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Rabbit
                                    </h2>
                                </div>
                            </div>
                            <div className="box__content">
                                <p>
                                    $AAA
                                </p>
                            </div>
                        </div> */}
                        {/* NMR */}
                        <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Numeraire
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Numeraire
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Numeraire
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Numeraire
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Numeraire
                                    </h2>
                                </div>
                            </div>
                            <div className="box__content">
                                <p>
                                    $NMR
                                </p>
                            </div>

                        </div>
                        {/* BAT */}
                        <div className="box">
                            {/* Basic Attention Token */}
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Basic
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Basic
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Basic
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Basic
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Basic
                                    </h2>
                                </div>
                            </div>
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Attention
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Attention
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Attention
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Attention
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Attention
                                    </h2>
                                </div>
                            </div>
                            <br />
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Token
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Token
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Token
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Token
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Token
                                    </h2>
                                </div>
                            </div>
                            <div className="box__content">
                                <p>
                                    $BAT
                                </p>
                            </div>

                        </div>
                        {/* WSBT */}
                        <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    WSB.sh
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        WSB.sh
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        WSB.sh
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        WSB.sh
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        WSB.sh
                                    </h2>
                                </div>
                            </div>
                            <div className="box__content">
                                <p>
                                    $WSBT
                                </p>
                            </div>


                        </div>
                        {/* MLN */}
                        {/* <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Enzyme
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Enzyme
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Enzyme
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Enzyme
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Enzyme
                                    </h2>
                                </div>
                            </div>
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Finance
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Finance
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Finance
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Finance
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Finance
                                    </h2>
                                </div>
                            </div>
                            <div className="box__content">
                                <p>
                                    $MLN
                                </p>
                            </div>

                        </div> */}
                        {/* UMA */}
                        <div className="box">
                            {/* Universal Market Access */}
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Universal
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Universal
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Universal
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Universal
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Universal
                                    </h2>
                                </div>
                            </div>
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Market
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Market
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Market
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Market
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Market
                                    </h2>
                                </div>
                            </div>
                            <br />
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Access
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Access
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Access
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Access
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Access
                                    </h2>
                                </div>
                            </div>
                            <div className="box__content">
                                <p>
                                    $UMA
                                </p>
                            </div>

                        </div>
                        {/* ARCH */}
                        {/* <div className="box">
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Archon
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Archon
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Archon
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Archon
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Archon
                                    </h2>
                                </div>
                            </div>
                            <div className="box__title-wrap">
                                <h2 className="original__title">
                                    Network
                                </h2>
                                <div className="box__title-inner left-top">
                                    <h2 >
                                        Network
                                    </h2>
                                </div>
                                <div className="box__title-inner left-bottom">
                                    <h2>
                                        Network
                                    </h2>
                                </div>
                                <div className="box__title-inner right-top">
                                    <h2>
                                        Network
                                    </h2>
                                </div>
                                <div className="box__title-inner right-bottom">
                                    <h2>
                                        Network
                                    </h2>
                                </div>
                            </div>

                            <div className="box__content">
                                <p>
                                    $ARCH
                                </p>
                            </div>

                        </div> */}


                    </div>
                </div>
            </div>
        </div>
    )
}