import React from 'react'
import Main from "./pages/Main/main.js"
import "./index.css"
import { MainContent } from './components/MainContent/index.js';
import { Navigation } from './components/Navigation/index.js';
import { ModalWindow }   from './components/Contacts/index.js';

function App() {
  return (
    <div style={{position: 'relative', height: '100%',overflow: 'hidden'}} >
        <div className="loading-wrap"></div>
        <Navigation />
        <ModalWindow />
        <Main />
        <MainContent />
        <span className="mouseIMG" style={{
          position: 'absolute',
          width:'20px',
          height:'20px',
          border:"1px solid white",
          borderRadius:"50%",
          transform:'translate(-50%,-50%)',
          transition:'width 0.1s ease-out, height 0.1s ease-out'

         }}></span>
    </div>
  );
}

export default App;
