import React, { useEffect, useRef } from 'react'
import "./style.css"
import * as THREE from 'three'
import * as CANNON from 'cannon-es'
import gsap from 'gsap'
import bat from './assets/logo/bat/bat-min.jpg'
import mln from './assets/logo/mln/mln-min.jpg'
import sys from './assets/logo/sys/sys-min.jpg'
import wsb from './assets/logo/wsb/wsb-min.jpg'
import uma from './assets/logo/uma/uma-min.jpg'
import aaa from './assets/logo/aaa/aaa-min.jpg'
import nmr from './assets/logo/nmr/nmr-min.jpg'
import arch from './assets/logo/arch/arch-min.jpg'


import aaaT_G from './assets/logo/aaa/aaa-blur.jpg'
import batT_G from './assets/logo/bat/bat-blur.jpg'
import nmrT_G from './assets/logo/nmr/nmr-blur.jpg'
import wsbT_G from './assets/logo/wsb/wsbt-blur.jpg'
import mlnT_G from './assets/logo/mln/mln-blur2.jpg'
import sysT_G from './assets/logo/sys/sys-blur.jpg'
import umaT_G from './assets/logo/uma/uma-blur.jpg'
import archT_G from './assets/logo/arch/arch-blur.jpg'




export default function Main() {
    const cubeRef = useRef(null)

    useEffect(() => {


        let manager1 = new THREE.LoadingManager();
        let manager2 = new THREE.LoadingManager();
        let manager3 = new THREE.LoadingManager();
        let manager4 = new THREE.LoadingManager();
        let manager5 = new THREE.LoadingManager();
        let manager6 = new THREE.LoadingManager();
        let manager7 = new THREE.LoadingManager();
        let manager8 = new THREE.LoadingManager();

        let loadingArr = [{}, {}, {}, {}, {}, {}, {}, {}]
        let boxSizes = {
            width: 3.6,
            height: 6,
            depth: 0.4
        }

        if (window.innerWidth < 800) {
            boxSizes.width = 3
            boxSizes.height = 5.4
        }
        let scene = new THREE.Scene();
        let scene1 = new THREE.Scene();


        let imgLoader1 = new THREE.TextureLoader(manager1)
        let imgLoader2 = new THREE.TextureLoader(manager2)
        let imgLoader3 = new THREE.TextureLoader(manager3)
        let imgLoader4 = new THREE.TextureLoader(manager4)
        let imgLoader5 = new THREE.TextureLoader(manager5)
        let imgLoader6 = new THREE.TextureLoader(manager6)
        let imgLoader7 = new THREE.TextureLoader(manager7)
        let imgLoader8 = new THREE.TextureLoader(manager8)

        manager1.onProgress = function (item, loaded, total) {
            loadingArr[0].progress = loaded / total * 100
        };
        manager2.onProgress = function (item, loaded, total) {
            loadingArr[1].progress = loaded / total * 100
        };
        manager3.onProgress = function (item, loaded, total) {
            loadingArr[2].progress = loaded / total * 100
        };
        manager4.onProgress = function (item, loaded, total) {
            loadingArr[3].progress = loaded / total * 100
        };
        manager5.onProgress = function (item, loaded, total) {
            loadingArr[4].progress = loaded / total * 100
        };
        manager6.onProgress = function (item, loaded, total) {
            loadingArr[5].progress = loaded / total * 100
        };
        manager7.onProgress = function (item, loaded, total) {
            loadingArr[6].progress = loaded / total * 100
        };
        manager8.onProgress = function (item, loaded, total) {
            loadingArr[7].progress = loaded / total * 100
        };

        const camera = new THREE.PerspectiveCamera(
            35,
            window.innerWidth / window.innerHeight,
            0.1,
            100000
        );
        camera.position.z = 40;

        let background = []
        let boxBg = []





        //   ACTIVE CARDS

        let activeTicker = []
        let imgLoader = new THREE.TextureLoader()

        let batLoaderON = imgLoader.load(bat)
        batLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        batLoaderON.encoding = THREE.sRGBEncoding

        // let mlnLoaderON = imgLoader.load(mln)
        // mlnLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        // mlnLoaderON.encoding = THREE.sRGBEncoding

        let sysLoaderON = imgLoader.load(sys)
        sysLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        sysLoaderON.encoding = THREE.sRGBEncoding

        let wsbLoaderON = imgLoader.load(wsb)
        wsbLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        wsbLoaderON.encoding = THREE.sRGBEncoding

        let umaLoaderON = imgLoader.load(uma)
        umaLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        umaLoaderON.encoding = THREE.sRGBEncoding

        // let aaaLoaderON = imgLoader.load(aaa)
        // aaaLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        // aaaLoaderON.encoding = THREE.sRGBEncoding

        let nmrLoaderON = imgLoader.load(nmr)
        nmrLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        nmrLoaderON.encoding = THREE.sRGBEncoding

        // let archLoaderON = imgLoader.load(arch)
        // archLoaderON.mapping = THREE.EquirectangularReflectionMapping;
        // archLoaderON.encoding = THREE.sRGBEncoding


        activeTicker.push(sysLoaderON)
        // activeTicker.push(aaaLoaderON)
        activeTicker.push(nmrLoaderON)
        activeTicker.push(batLoaderON)
        activeTicker.push(wsbLoaderON)
        // activeTicker.push(mlnLoaderON)
        activeTicker.push(umaLoaderON)
        // activeTicker.push(archLoaderON)



        // GRADIENT
        function progressLoad(item, loaded, total) {
            // console.log({item:item,progress:loaded/total*100})
        }
        let batLoader = imgLoader1.load(batT_G)
        batLoader.mapping = THREE.EquirectangularReflectionMapping;
        batLoader.encoding = THREE.sRGBEncoding


        // let mlnLoader = imgLoader2.load(mlnT_G)
        // mlnLoader.mapping = THREE.EquirectangularReflectionMapping;
        // mlnLoader.encoding = THREE.sRGBEncoding

        let sysLoader = imgLoader3.load(sysT_G)
        sysLoader.mapping = THREE.EquirectangularReflectionMapping;
        sysLoader.encoding = THREE.sRGBEncoding

        let wsbLoader = imgLoader4.load(wsbT_G)
        wsbLoader.mapping = THREE.EquirectangularReflectionMapping;
        wsbLoader.encoding = THREE.sRGBEncoding

        let umaLoader = imgLoader5.load(umaT_G)
        umaLoader.mapping = THREE.EquirectangularReflectionMapping;
        umaLoader.encoding = THREE.sRGBEncoding

        // let aaaLoader = imgLoader6.load(aaaT_G)
        // aaaLoader.mapping = THREE.EquirectangularReflectionMapping;
        // aaaLoader.encoding = THREE.sRGBEncoding

        let nmrLoader = imgLoader7.load(nmrT_G)
        nmrLoader.mapping = THREE.EquirectangularReflectionMapping;
        nmrLoader.encoding = THREE.sRGBEncoding

        // let archLoader = imgLoader8.load(archT_G)
        // archLoader.mapping = THREE.EquirectangularReflectionMapping;
        // archLoader.encoding = THREE.sRGBEncoding



        background.push(sysLoader)
        // background.push(aaaLoader)
        background.push(nmrLoader)
        background.push(batLoader)
        background.push(wsbLoader)
        // background.push(mlnLoader)
        background.push(umaLoader)
        // background.push(archLoader)


        let testCube = new THREE.CubeTextureLoader().load([
            batT_G,
            batT_G,
            batT_G,
            batT_G,
            batT_G,
            batT_G
        ]);




        //   boxBg.push(new THREE.TextureLoader().load( './img/1/nmr.png' ))
        //   boxBg.push(new THREE.TextureLoader().load( './img/2/aaa.png' ))
        //   boxBg.push(new THREE.TextureLoader().load( './img/3/wsbt2.png' ))
        //   boxBg.push(new THREE.TextureLoader().load( './img/4/min.png' ))
        //   boxBg.push(new THREE.TextureLoader().load( './img/5/uma.png' ))
        //   boxBg.push(new THREE.TextureLoader().load( './img/6/sys.png' ))
        //   boxBg.push(new THREE.TextureLoader().load( './img/7/bat.png' ))





        // init renderer
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        // renderer.setPixelRatio(2)
        cubeRef.current.appendChild(renderer.domElement);


        const world = new CANNON.World({
            gravity: new CANNON.Vec3(0, 0, 0)
        })


        const groundBody = new CANNON.Body({
            shape: new CANNON.Plane(),
            mass: 0,
            position: new CANNON.Vec3(0, -10, 0)
            // type: CANNON.Body.STATIC
        })

        const topBody = new CANNON.Body({
            shape: new CANNON.Plane(),
            mass: 0,
            position: new CANNON.Vec3(0, 10, 0)
            // type: CANNON.Body.STATIC
        })

        const frontBody = new CANNON.Body({
            shape: new CANNON.Plane(),
            mass: 0,
            position: new CANNON.Vec3(0, 0, 10)
            // type: CANNON.Body.STATIC
        })

        let backBody
        let leftBody
        let rightBody
        // adaptive window

        backBody = new CANNON.Body({
            shape: new CANNON.Plane(),
            mass: 0,
            position: new CANNON.Vec3(0, 0, -25)
            // type: CANNON.Body.STATIC
        })
        leftBody = new CANNON.Body({
            shape: new CANNON.Plane(),
            mass: 0,
            position: new CANNON.Vec3(-11 * window.innerWidth / window.innerHeight, 0)
            // type: CANNON.Body.STATIC
        })
        rightBody = new CANNON.Body({
            shape: new CANNON.Plane(),
            mass: 0,
            position: new CANNON.Vec3(11 * window.innerWidth / window.innerHeight, 0, 0)
            // type: CANNON.Body.STATIC
            // window.innerWidth/40/2.5
        })


        let light = new THREE.AmbientLight(0xffffff)
        //   scene.add(light)

        let dLight = new THREE.DirectionalLight(0xffffff, 0.3)
        dLight.position.z = 15
        dLight.position.y = 4
        dLight.position.x = 11 * window.innerWidth / window.innerHeight

        let dLight2 = new THREE.DirectionalLight(0xffffff, 0.3)
        dLight2.position.z = 15
        dLight2.position.y = 4
        dLight2.position.x = -11 * window.innerWidth / window.innerHeight


        let dLight3 = new THREE.DirectionalLight(0xffffff, 0.3)
        dLight3.position.z = 15
        dLight3.position.y = -4
        dLight3.position.x = 11 * window.innerWidth / window.innerHeight

        let dLight4 = new THREE.DirectionalLight(0xffffff, 0.3)
        dLight4.position.z = 15
        dLight4.position.y = -4
        dLight4.position.x = -11 * window.innerWidth / window.innerHeight


        let dLight5 = new THREE.DirectionalLight(0xffffff, 0.3)
        dLight5.position.z = 15
        dLight5.position.y = 2



        scene.add(dLight);
        scene.add(dLight2);
        scene.add(dLight3);
        scene.add(dLight4);
        scene.add(dLight5);


        const spotLight = new THREE.SpotLight(0xffffff);
        // spotLight.position.set( 0, 4, 40 );
        spotLight.position.set(0, -40, 40);
        // spotLight.castShadow = true;

        spotLight.shadow.mapSize.width = 2048;
        spotLight.shadow.mapSize.height = 2048;

        // spotLight.shadow.camera.near = 500;
        // spotLight.shadow.camera.far = 400;
        // spotLight.shadow.camera.fov = 30;



        // const spotLight = new THREE.SpotLight( 0xffffff );
        // spotLight.position.set( 100, 1000, 100 );

        // spotLight.castShadow = true;

        // spotLight.shadow.mapSize.width = 1024;
        // spotLight.shadow.mapSize.height = 1024;

        // spotLight.shadow.camera.near = 500;
        // spotLight.shadow.camera.far = 4000;
        // spotLight.shadow.camera.fov = 30;

        // scene.add( spotLight );



        frontBody.quaternion.setFromEuler(0, Math.PI, 0)
        rightBody.quaternion.setFromEuler(0, -Math.PI / 2, 0)
        leftBody.quaternion.setFromEuler(0, Math.PI / 2, 0)
        groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0)
        topBody.quaternion.setFromEuler(Math.PI / 2, 0, 0)

        world.addBody(frontBody)
        world.addBody(rightBody)
        world.addBody(leftBody)
        world.addBody(backBody)
        world.addBody(groundBody)
        world.addBody(topBody)

        const geometry = new THREE.PlaneGeometry(50 * window.innerWidth, 50 * window.innerWidth);
        const material = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            // color: 0xff0000
        });
        const material2 = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            // color: 0x000000 
        });

        renderer.gammaInput = true;
        renderer.gammaOutput = true;
        const groundMesh = new THREE.Mesh(geometry, material);
        scene.add(groundMesh);

        const topMesh = new THREE.Mesh(geometry, material);
        scene.add(topMesh);

        const backMesh = new THREE.Mesh(geometry, material);
        scene.add(backMesh);

        const leftMesh = new THREE.Mesh(geometry, material);
        scene.add(leftMesh);

        const rightMesh = new THREE.Mesh(geometry, material);
        scene.add(rightMesh);

        const frontMesh = new THREE.Mesh(geometry, material);
        scene.add(frontMesh);

        const testmaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
        const testgeometry = new THREE.PlaneGeometry(window.innerWidth + window.innerHeight, window.innerWidth + window.innerHeight);
        const testMesh = new THREE.Mesh(testgeometry, testmaterial);
        testMesh.position.z = -25 * window.innerWidth / window.innerHeight
        //   scene.add(testMesh);

        const boxGeo = new THREE.BoxGeometry(boxSizes.width, boxSizes.height, boxSizes.depth)
        // const boxGeo = new THREE.BoxGeometry(4,8,0.2)


        const BMaterials = []
        const Boxes = []
        //   let ActiveBoxFrame = new THREE.Mesh(new THREE.BoxGeometry(boxSizes.width ,boxSizes.height,2),boxMat)
        //   ActiveBoxFrame.rotation.z = 2
        //   ActiveBoxFrame.rotation.y = 1
        // //   scene.add(ActiveBoxFrame)
        // let boxFrame = []
        // let ActiveFrameMaterial = new THREE.LineBasicMaterial({
        //     color: 0xffffff
        // })

        // boxFrame.push( new THREE.Vector3( -1.8,3,0 ) );
        // boxFrame.push( new THREE.Vector3( 1.8, 3, 0 ) );
        // boxFrame.push( new THREE.Vector3( 1.8, -3, 0 ) );
        // boxFrame.push( new THREE.Vector3( -1.8, -3, 0 ) );
        // boxFrame.push( new THREE.Vector3( 0, 0, 0 ) );

        // const boxFrameLine = new THREE.BufferGeometry().setFromPoints( boxFrame );
        // const FrameLine = new THREE.Line( boxFrameLine, ActiveFrameMaterial );
        // scene.add( FrameLine );



        for (let i = 0; i < background.length; i++) {

            const meshBMaterial = new THREE.MeshPhongMaterial({
                emissive: 0x000000,
                refractionRatio: 1,
                shininess: 100,
                specular: 0x111111,
                reflectivity: 1,
                envMap: background[i]

            })

            const box = new THREE.Mesh(boxGeo, meshBMaterial)
            BMaterials.push(meshBMaterial)
            Boxes.push(box);
            box.scale.set(1, 1, 1)
            scene.add(box);
        }



        let BoxBodies = []
        for (let i = 0; i < background.length; i++) {
            let plusX = 1
            let plusY = 1
            if (Math.random() > 0.5) {
                plusX = plusX * (-1)
                plusY = plusY * (-1)
            }

            let posX = Math.random() * 10 * plusX
            let posY = Math.random() * 10 * plusY
            const boxBody = new CANNON.Body({
                mass: 0.00001,
                shape: new CANNON.Box(new CANNON.Vec3(boxSizes.width, boxSizes.height, boxSizes.depth)),
                position: new CANNON.Vec3(posX, posY, 9.5 - ((boxSizes.depth + boxSizes.depth) * i))
            })

            BoxBodies.push(boxBody)
            world.addBody(boxBody)
        }

        let changeCoordObj = {
            x: 0, y: 0, z: 0
        }

        function changeCoord(obj, coord) {
            const arrayNew = obj.array

            obj.array = arrayNew
            gsap.to(changeCoordObj, {
                x: coord.x,
                y: coord.y,
                z: coord.z,
                duration: 1.5
            })
            arrayNew[3] = changeCoordObj.x
            arrayNew[4] = changeCoordObj.y
            arrayNew[5] = changeCoordObj.z
        }

        const timeStep = 1 / 60;
        const raycaster = new THREE.Raycaster();
        const raycaster2 = new THREE.Raycaster();

        const mouse = new THREE.Vector2();
        const mouseMove = new THREE.Vector2();
        const mouseSPAN = document.querySelector(".mouseIMG")
        function gsapMouseImg(element, array) {
            const posX = array.left
            const posY = array.top
            gsap.to(element, {
                left: posX,
                top: posY,
                duration: 0.3
            })
        }
        function gsapSpan(element, array) {
            const posX = array.left
            const posY = array.top
            gsap.to(element, {
                left: posX,
                top: posY,
                duration: 0.4,
                // transform : `tranlate(-50%,-50%)`
            })
        }
        function gsaoMOuseImgSize(element, array) {
            const itemWidth = array.width
            const itemHeight = array.height
            const lwidth = array.linewidth
            gsap.to(element, {
                width: itemWidth,
                height: itemHeight,
                borderWidth: lwidth,
                duration: 0.1
            })
        }
        let aaFlag = false
        let navSpans = document.querySelectorAll(".navigation__item-span")
        let contactSpans = document.querySelectorAll(".contacts__span")
        customLink(navSpans)
        customLink(contactSpans)

        function customLink(element) {
            element.forEach(item => {
                item.onmousemove = (event) => {
                    if (window.innerWidth > 1024) {
                        if (aaFlag !== event.currentTarget) {
                            aaFlag = event.currentTarget
                        }
                        let posXAA = event.layerX
                        let posYAA = event.layerY
                        gsapSpan(aaFlag.children[0], { left: `${posXAA}px`, top: `${posYAA}px` })
                    }
                }
                item.onmouseout = (event) => {
                    aaFlag = false
                    if (window.innerWidth > 1024 && !aaFlag) {
                        gsapSpan(event.currentTarget.children[0], { left: `50%`, top: `50%` })
                    }
                }
            })
        }
        let spans = document.querySelectorAll(".navigation__item-span a")
        let spanC = document.querySelectorAll(".contacts__span a")

        function setASize(array) {
            if (window.innerWidth > 1024) {
                array.forEach(item => {
                    item.parentNode.style.width = `${item.getBoundingClientRect().width}px`
                })
            }

        }
        // setASize(spanC)
        // setASize(spans)



        function onMouseMove(event) {
            mouseMove.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouseMove.y = -(event.clientY / window.innerHeight) * 2 + 1;
            if (window.innerWidth > 1024) {


                gsapMouseImg(mouseSPAN.style, { left: `${event.clientX}px`, top: `${event.clientY}px` })
                //     const eventTarget = event.target
                //     let aaFlag = false
                //     for (let i = 0; i < navigationLink.children.length; i++) {
                //         const element = navigationLink.children[i].children[0];
                //         checkLink(element)
                //         if (element !== aaFlag) {
                //             gsapSpan(element,{left:`50%`,top:`50%`})   
                //             console.log(aaFlag)  
                //         }else{
                //             console.log(aaFlag)  
                //         }
                //     }
                //     for (let index = 0; index < document.querySelector(".contacts__button-wrap").children.length; index++) {
                //         const element = document.querySelector(".contacts__button-wrap").children[index].children[0];
                //         checkLink(element)
                //         if (element !== aaFlag) {
                //             console.log(aaFlag)  
                //             // gsapSpan(element,{left:`50%`,top:`50%`})
                //         }else{console.log(aaFlag)}  
                //     }
                //     function checkLink(element){
                //         if (eventTarget === element) {
                //             aaFlag = element
                //         }else{
                //             // gsapSpan(element,{left:`0px`,top:`0px`})
                //         }
                //     }
                if (aaFlag) {
                    // console.log(aaFlag)
                    gsaoMOuseImgSize(mouseSPAN.style, { width: `${aaFlag.scrollWidth + 10}px`, height: `${aaFlag.scrollWidth + 10}px`, linewidth: `2px` })
                    // gsapSpan(aaFlag,{left:`${event.clientX - aaFlag.getBoundingClientRect().left }px`,top:`${event.clientY - aaFlag.getBoundingClientRect().top}px`})
                } else {
                    gsaoMOuseImgSize(mouseSPAN.style, { width: `${20}px`, height: `${20}px`, linewidth: `1px` })
                }
            }
            const intersects = raycaster2.intersectObjects(scene.children);
            let mesh
            check()
            function check() {
                for (let i = 0; i < intersects.length; i++) {
                    const element = intersects[i];
                    let found = find(element.object)
                    if (found) {
                        mesh = found
                        return
                    }
                }
            }
            function find(element) {
                for (let y = 0; y < Boxes.length; y++) {
                    const secElement = Boxes[y];
                    if (element === secElement) {
                        return element
                    }
                }
            }
            if (mesh) {
                document.body.style.cursor = 'pointer'
            } else {
                document.body.style.cursor = 'default'
            }

        }
        let selected
        let activeContent = 0
        let lineCenter = { x: 0, y: 0 }
        let linkItems = document.querySelectorAll(".navigation__item")
        let navigationLink = document.querySelector(".navigation__list")

        function mouseMoveGsap(obj, coordObj) {
            gsap.to(obj.rotation, {
                x: coordObj.y / 20,
                y: -coordObj.x / 10,
                duration: 2
            })
        }

        let sceneBGArr = []
        let activeBGArr = []
        function loadBackgrounds() {
            for (let i = 0; i < activeTicker.length; i++) {
                const element2 = activeTicker[i];
                Boxes[i].material.envMap = element2
                sceneBGArr.push(Boxes[i].material.envMap)
            }
            scene1.background = null
        }
        function setBG() {
            for (let i = 0; i < Boxes.length; i++) {
                Boxes[i].material.envMap = background[i]
            }
        }
        loadBackgrounds()
        setBG()
        const contentBoxes = document.querySelectorAll(".content .box")
        let prewClick = null
        function onClick(id, e) {
            // if (!document.body.classList.contains("modal-open")) {
            mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);

            const intersects = raycaster.intersectObjects(scene.children);
            // console.log(intersects)
            let mesh

            if (isNaN(id)) {
                mesh = check(intersects)
            }

            let flag = Boxes.filter(e => e === mesh)


            let indenty

            if (!isNaN(id)) {
                indenty = id
            }


            if (isNaN(id)) {
                for (let i = 0; i < Boxes.length; i++) {
                    const element = Boxes[i];

                    if (element === mesh) {
                        indenty = i
                    }
                }
            }


            if (!document.body.classList.contains("modal-open")) {
                pull()
                if (indenty !== undefined) {
                    setBGAll()
                    setActive()
                }

                if (
                    indenty === undefined &&
                    e.target.localName !== 'h2' &&
                    e.target.localName !== 'a' &&
                    e.target.localName !== 'p' &&
                    e.target.localName !== 'li' &&
                    e.target.localName !== 'span' &&
                    e.target.localName !== 'div'
                ) {
                    setDefaultBGAll()
                    removeStart()
                }
            }



            function firstClickPull() {
                let element = BoxBodies[indenty];
                pullElement(element)
                setStart()
            }
            function setStart() {
                document.body.classList.add("start")
                contentBoxes[indenty].classList.add("active")
                linkItems[indenty].classList.add("active")
                document.querySelector(".main-content__title-top .box").classList.remove("active")
                setLineOpacity(false, 1)
            }
            function removeStart() {
                document.body.classList.remove("start")
                document.querySelector(".main-content__title-top .box").classList.add("active")
                for (let i = 0; i < Boxes.length; i++) {
                    contentBoxes[i].classList.remove("active")
                    linkItems[i].classList.remove("active")
                }
                setLineOpacity(true, 0)

            }
            function pullElement(element) {
                element.applyLocalImpulse({ x: 0, y: 0, z: -0.00020 })
                for (let i = contentBoxes.length - 1; i >= 0; i--) {
                    const element = contentBoxes[i];
                    element.classList.remove("active")
                    linkItems[i].classList.remove("active")
                }
            }
            function pull() {
                if (flag.length > 0 || !isNaN(id)) {
                    selected = mesh
                    if (!isNaN(id)) {
                        selected = BoxBodies[id]
                    }
                    // firstClick = true
                    firstClickPull()


                }
            }
            // function firstPull(){
            //     for (let i = contentBoxes.length-1; i >= 0; i--) {
            //         const element = BoxBodies[i];
            //         element.position.z = element.position.z + 5
            //     }
            // }
            function setActive() {
                if ((flag.length > 0 && selected) || !isNaN(id)) {
                    Boxes[indenty].material.envMap = sceneBGArr[indenty]
                }
            }
            function setBGAll() {
                if ((flag.length > 0 && selected) || !isNaN(id)) {
                    for (let i = 0; i < Boxes.length; i++) {
                        Boxes[i].material.envMap = background[indenty]
                    }
                }
            }
            function setDefaultBGAll() {
                // if ((flag.length>0 && selected) || !isNaN(id)) {
                for (let i = 0; i < Boxes.length; i++) {
                    Boxes[i].material.envMap = background[i]
                }
                // }
            }
        }

        function check(intersects) {
            for (let i = 0; i < intersects.length; i++) {
                const element = intersects[i];
                let found = find(element.object)
                if (found) {
                    return found
                }
            }
        }
        function find(element) {
            for (let y = 0; y < Boxes.length; y++) {
                const secElement = Boxes[y];
                if (element === secElement) {
                    return element
                }
            }
        }

        for (let i = 0; i < linkItems.length; i++) {
            const linkItem = linkItems[i];
            linkItem.children[0].onclick = (e) => {
                let identity
                let currentTarget = e.currentTarget
                let currentListItem = e.currentTarget.parentNode
                for (let i = 0; i < navigationLink.children.length; i++) {
                    const element = navigationLink.children[i];
                    element.classList.remove("active")
                    if (element === currentListItem) {
                        identity = i

                    }
                }
                currentTarget.classList.add("active")
                onClick(identity, e)
            }
        }

        // const controls = new OrbitControls(camera, renderer.domElement)
        // controls.update()
        let firstClick = false
        const points1 = [];
        const points2 = [];
        const points3 = [];
        const points4 = [];
        const materialLine = new THREE.LineBasicMaterial({
            color: 0xffffff,
            linewidth: 2,
            transparent: true,
            //   opacity: 1,
            linecap: 'round', //ignored by WebGLRenderer
            linejoin: 'round'
        })


        points2.push(new THREE.Vector3(0, -window.innerHeight / 25, 9.5));
        points2.push(new THREE.Vector3(0, 0, 9.5));

        const geometryLine = new THREE.BufferGeometry().setFromPoints(points2);
        const line2 = new THREE.Line(geometryLine, materialLine);
        scene.add(line2);

        points1.push(new THREE.Vector3(0, window.innerHeight / 25, 9.5));
        points1.push(new THREE.Vector3(0, 0, 9.5));
        const geometryLine1 = new THREE.BufferGeometry().setFromPoints(points1);
        const line = new THREE.Line(geometryLine1, materialLine);
        scene.add(line);

        points3.push(new THREE.Vector3(window.innerWidth / 25, 0, 9.5));
        points3.push(new THREE.Vector3(0, 0, 9.5));
        const geometryLine3 = new THREE.BufferGeometry().setFromPoints(points3);
        const line3 = new THREE.Line(geometryLine3, materialLine);
        scene.add(line3);

        points4.push(new THREE.Vector3(-window.innerWidth / 25, 0, 9.5));
        points4.push(new THREE.Vector3(0, 0, 9.5));
        const geometryLine4 = new THREE.BufferGeometry().setFromPoints(points4);
        const line4 = new THREE.Line(geometryLine4, materialLine);
        scene.add(line4);

        window.addEventListener("mousemove", onMouseMove, false);
        window.onclick = (e) => onClick(undefined, e);
        //   window.ontouch = onClick
        window.onload = () => {
            onLoad()

        }
        function setLineOpacity(booleans, value) {
            line.material.transparent = booleans
            line2.material.transparent = booleans
            line3.material.transparent = booleans
            line4.material.transparent = booleans
            line.material.opacity = value
            line2.material.opacity = value
            line3.material.opacity = value
            line4.material.opacity = value
        }

        function onLoad() {
            document.body.classList.add("loaded")
            document.querySelector(".main-content__title-top .box").classList.add("active")
        }


        function isNotSelected(id) {
            if (!selected) {
                selected = Boxes[id]
            }
            // linkItems[id].classList.add("active")
        }
        isNotSelected(0)
        setLineOpacity(true, 0)
        function animate() {
            world.step(timeStep);
            raycaster2.setFromCamera(mouseMove, camera);

            if (selected) {
                changeCoord(line.geometry.attributes.position, selected.position)
                changeCoord(line2.geometry.attributes.position, selected.position)
                changeCoord(line3.geometry.attributes.position, selected.position)
                changeCoord(line4.geometry.attributes.position, selected.position)
            }

            line.geometry.attributes.position.needsUpdate = true;
            line2.geometry.attributes.position.needsUpdate = true;
            line3.geometry.attributes.position.needsUpdate = true;
            line4.geometry.attributes.position.needsUpdate = true;


            if (firstClick) {
                // mouseMoveGsap(camera,mouse)
            }


            // planes
            // bottom
            groundMesh.position.copy(groundBody.position);
            groundMesh.quaternion.copy(groundBody.quaternion);
            // top
            topMesh.position.copy(topBody.position);
            topMesh.quaternion.copy(topBody.quaternion);
            // back
            backMesh.position.copy(backBody.position);
            backMesh.quaternion.copy(backBody.quaternion);
            // left
            leftMesh.position.copy(leftBody.position);
            leftMesh.quaternion.copy(leftBody.quaternion);
            // right
            rightMesh.position.copy(rightBody.position);
            rightMesh.quaternion.copy(rightBody.quaternion);
            // front
            frontMesh.position.copy(frontBody.position);
            frontMesh.quaternion.copy(frontBody.quaternion);

            // boxes
            for (let i = 0; i < Boxes.length; i++) {
                const element = Boxes[i];
                const elementBody = BoxBodies[i];
                element.position.copy(elementBody.position);
                element.quaternion.copy(elementBody.quaternion);
            }




            requestAnimationFrame(animate);
            renderer.render(scene, camera);

        }

        animate();

        function onWindowResize() {
            navSpans.forEach(item => {
                item.onmousemove = (event) => {
                    if (window.innerWidth > 1024) {
                        if (aaFlag !== event.currentTarget) {
                            aaFlag = event.currentTarget
                        }
                        let posXAA = event.layerX
                        let posYAA = event.layerY
                        gsapSpan(aaFlag.children[0], { left: `${posXAA}px`, top: `${posYAA}px` })
                    }
                }
                item.onmouseout = (event) => {
                    aaFlag = false
                    if (window.innerWidth > 1024 && !aaFlag) {
                        gsapSpan(event.currentTarget.children[0], { left: `50%`, top: `50%` })
                    }
                }
            })
            contactSpans.forEach(item => {
                item.onmousemove = (event) => {
                    if (window.innerWidth > 1024) {
                        if (aaFlag !== event.currentTarget) {
                            aaFlag = event.currentTarget
                        }
                        let posXAA = event.layerX
                        let posYAA = event.layerY
                        gsapSpan(aaFlag.children[0], { left: `${posXAA}px`, top: `${posYAA}px` })
                    }
                }
                item.onmouseout = (event) => {
                    aaFlag = false
                    if (window.innerWidth > 1024 && !aaFlag) {
                        gsapSpan(event.currentTarget.children[0], { left: `50%`, top: `50%` })
                    }
                }
            })

            // setASize(spans)
            // setASize(spanC)
            dLight.position.y = 11 * window.innerWidth / window.innerHeight
            dLight.position.x = 11 * window.innerWidth / window.innerHeight
            dLight2.position.y = 11 * window.innerWidth / window.innerHeight
            dLight2.position.x = -11 * window.innerWidth / window.innerHeight
            dLight3.position.y = -11 * window.innerWidth / window.innerHeight
            dLight3.position.x = 11 * window.innerWidth / window.innerHeight
            dLight4.position.y = -11 * window.innerWidth / window.innerHeight
            dLight4.position.x = -11 * window.innerWidth / window.innerHeight


            testMesh.position.z = -10 * window.innerWidth / window.innerHeight
            testMesh.geometry.parameters.height = window.innerWidth + window.innerHeight
            testMesh.geometry.parameters.width = window.innerWidth + window.innerHeight
            line.geometry.attributes.position.array[1] = -window.innerHeight / 25
            line2.geometry.attributes.position.array[1] = window.innerHeight / 25
            line4.geometry.attributes.position.array[0] = window.innerWidth / 25
            line3.geometry.attributes.position.array[0] = -window.innerWidth / 25

            line.geometry.attributes.position.needsUpdate = true;
            line2.geometry.attributes.position.needsUpdate = true;
            line3.geometry.attributes.position.needsUpdate = true;
            line4.geometry.attributes.position.needsUpdate = true;

            //   backBody.position = new CANNON.Vec3(0,0,-15) 
            leftBody.position = new CANNON.Vec3(-11 * window.innerWidth / window.innerHeight, 0)
            // type: CANNON.Body.STATIC

            rightBody.position = new CANNON.Vec3(11 * window.innerWidth / window.innerHeight, 0, 0)
            camera.aspect = (window.innerWidth / window.innerHeight);

            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.updateProjectionMatrix();
        }
        window.addEventListener('resize', onWindowResize);
    }, [])


    return (
        <>
            <div className="canvas__wrapper" ref={cubeRef}  >

            </div>
        </>
    )
}