import React, { useEffect } from "react";
import "./style.css"


export const Navigation = () => {



    return (
        <>
            <div className="navigation__wrapper">
                <h3 className="navigation__title">
                    portfolio
                </h3>
                <nav className="navigation">
                    <ul className="navigation__list">
                        <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>
                                    $SYS
                                </a>
                                <span className="span__hidden">
                                    $SYS
                                </span>
                            </div>
                        </li>
                        {/* <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>
                                    $AAA
                                </a>
                                <span className="span__hidden">
                                    $AAA
                                </span>
                            </div>
                        </li> */}
                        <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>
                                    $NMR
                                </a>
                                <span className="span__hidden">
                                    $NMR
                                </span>
                            </div>
                        </li>
                        <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>$BAT</a>
                                <span className="span__hidden">
                                    $BAT
                                </span>
                            </div>
                        </li>
                        <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>$WSBT</a>
                                <span className="span__hidden">
                                    $WSBT
                                </span>
                            </div>
                        </li>
                        {/* <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>
                                    $MLN
                                </a>
                                <span className="span__hidden">
                                    $MLN
                                </span>
                            </div>
                        </li> */}
                        <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>
                                    $UMA
                                </a>
                                <span className="span__hidden">
                                    $UMA
                                </span>
                            </div>
                        </li>
                        {/* <li className="navigation__item">
                            <div className="navigation__item-span">
                                <a>
                                    $ARCH
                                </a>
                                <span className="span__hidden">
                                    $ARCH
                                </span>
                            </div>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </>
    )
}